import { string, object } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
const { emailRule, passwordRule, passwordRepeatRule } = validationRules()

export const signInSchema = toTypedSchema(
  object({
    email: emailRule,
    password: passwordRule,
  }),
)

export const signUpFirstSchema = toTypedSchema(
  object({
    email: emailRule,
  }),
)

export const signUpSecondSchema = toTypedSchema(
  object({
    username: string().required().min(3).max(16),
    newPassword: passwordRule,
    passwordConfirm: passwordRepeatRule,
  }),
)

export const recoverPasswordSchema = toTypedSchema(
  object({
    email: emailRule,
  }),
)

export const newPasswordSchema = toTypedSchema(
  object({
    newPassword: passwordRule,
    newPasswordRepeat: passwordRepeatRule,
  }),
)
